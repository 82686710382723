import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Main from "./pages/main/Main.js"
import SubscriptionApp from "./pages/subscriptionapp/SubscriptionApp.js"


function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Main />} />

            <Route path="subscriptionapp" element={<SubscriptionApp />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
