import './main.css';
import Nav from './components/NavBar';
import Header from './components/Hero';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';

function Main() {

  // Available Colours:
  // blue, cyan, gray, green, orange, pink, purple, red, teal, yellow

  const color = "teal";

  return (
    <>
      <Nav color={color} />
      <Header color={color} />
      <About color={color} />
      <Projects color={color} />
      <Contact color={color} />
      <Footer />
    </>
  );
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
export default Main;
