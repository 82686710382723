import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Image,
  Center,
  Button,
} from "@chakra-ui/react";
import ProfileArray from "./ProfileArray";


export default function About({ color }) {
  const profile = ProfileArray();

    return (
      <>
        <Container maxW={"3xl"} id="about">
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 14 }}
            pb={{ base: 20, md: 36 }}
          >
            <Stack align="center" direction="row" px={4}>
              <HStack mx={4}>
                <Text color={`${color}.400`} fontWeight={800}>
                  01
                </Text>
                <Text fontWeight={800} maxW={"md"}>
                  Acerca de mi
                </Text>
              </HStack>
              <Divider orientation="horizontal" maxW={500} />
            </Stack>

            <Center>
              <Image
                borderRadius="full"
                boxSize="180px"
                objectFit="cover"
                src="assets/myPhoto.png"
                alt="José Guerra"
              />
            </Center>

            <Text color={"gray.500"} fontSize={"xl"} px={4}>
              {profile.about}
            </Text>

            <Stack
              direction={"column"}
              spacing={3}
              align={"center"}
              alignSelf={"center"}
              position={"relative"}
            >
              <Button
                colorScheme={color}
                bg={`${color}.400`}
                rounded={"full"}
                px={6}
                _hover={{
                  bg: `${color}.500`,
                }}
                href="assets/CVJoseGuerra.pdf"
              >
                <a href="assets/CVJoseGuerra.pdf" download="">
                  Descargar CV
                </a>
              </Button>
            </Stack>
          </Stack>
        </Container>
      </>
    );
}

