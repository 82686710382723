import React from 'react';
import { HStack, Box, VStack, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { ChakraProvider, extendTheme } from '@chakra-ui/react';


const theme = extendTheme({
  fonts: {
    heading: 'Rubik, sans-serif',
    body: 'Rubik, sans-serif',
  },
});

function SubscriptionApp() {

  return (
    <ChakraProvider theme={theme}>
      <VStack  bg='#06151C'>
        <SimpleGrid 
          columns={{ base: 1, md: 2 }} // 1 columna en pantallas pequeñas, 2 en medianas
          spacing={10} 
          w="90%"
          margin="10px auto"
          alignItems="center"
          fontWeight="bold"
          style={{ font: 'Rubik' }}
        >

          <Box 
            order={{ base: 1, md: 1 }}
            display="flex" 
            flexDirection="column" 
            justifyContent="center" 
            alignItems="center" // Alinea todo al centro horizontalmente
            textAlign="center"  // Centra el texto
            paddingTop={{ base: '60px' }}
          >

            <Image src='assets/subscriptionapp/geld-logo.png' maxW={{ base: '120px', md: '180px' }} mb={4} borderRadius='full'  />
            <Text fontSize={{ base: '2xl', md: '5xl' }} marginBottom='10px' fontWeight="bold" >Geld</Text>


            <Text fontSize={{ base: '4xl', md: '6xl' }} marginBottom='20px' >
              Manage <Text as="span" color="#01A4DD">Subs</Text>,<br/>
              Maximize <Text as="span" color="#01A4DD">Savings</Text>.
            </Text>


            
            <Image src='assets/subscriptionapp/appstore.png'
              maxW={{ base: '150px', md: '200px' }} // Más grande en desktop (md), más pequeña en mobile (base)
              width="100%" // Ajusta el ancho al 100% del contenedor
              height="auto" // Mantiene la proporción de la imagen
              marginTop='20px'
            />

          </Box>


          <Box order={{ base: 2, md: 2 }} display="flex" justifyContent="center" >
            <Image 
              src='assets/subscriptionapp/screenshot1.png'
              maxW={{ base: '300px', md: '425px' }} // Más grande en desktop (md), más pequeña en mobile (base)
              width="100%" // Ajusta el ancho al 100% del contenedor
              height="auto" // Mantiene la proporción de la imagen
            />
          </Box>


          <Box order={{ base: 4, md: 3 }} display="flex" justifyContent="center">
            <Image 
              src='assets/subscriptionapp/screenshot2.png'
              maxW={{ base: '300px', md: '600px' }} // Más grande en desktop (md), más pequeña en mobile (base)
              width="100%" // Ajusta el ancho al 100% del contenedor
              height="auto" // Mantiene la proporción de la imagen
            />
          </Box>

          <Box 
            order={{ base: 3, md: 4 }}
            display="flex" 
            flexDirection="column" 
            justifyContent="center" 
            alignItems="center" // Alinea todo al centro horizontalmente
            textAlign="center"  // Centra el texto
          >
            <Text fontSize={{ base: '4xl', md: '6xl' }}>
              <Text as="span" color="#01A4DD">Personalize</Text> your subscriptions as you want
            </Text>


          </Box>



          <Box 
            order={{ base: 5, md: 5 }}
            display="flex" 
            flexDirection="column" 
            justifyContent="center" 
            alignItems="center" // Alinea todo al centro horizontalmente
            textAlign="center"  // Centra el texto
          >
            <Text fontSize={{ base: '4xl', md: '6xl' }}>
            Stay alert with <Text as="span" color="#01A4DD">notifications</Text>
            </Text>
          </Box>


          <Box order={{ base: 6, md: 6 }} display="flex" justifyContent="center" paddingBottom='40px'>
            <Image 
              src='assets/subscriptionapp/screenshot3.png'
              maxW={{ base: '300px', md: '900px' }} // Más grande en desktop (md), más pequeña en mobile (base)
              width="100%" // Ajusta el ancho al 100% del contenedor
              height="auto" // Mantiene la proporción de la imagen
            />
          </Box>

          



        </SimpleGrid>


        <Box 
          as="footer" 
          w="100%" 
          bg="#121717"  
          py={4}  // Padding vertical
          textAlign="center"
          mt={8} // Margen superior
          color='#AAAAAA'
        >
          <Text paddingY='2px' >Created with ❤️ by Jose Guerra</Text>

          <HStack justifyContent="center" mt={2} paddingY='5px'>
            <a href="https://github.com/Guerra-09">
              <Image src="assets/subscriptionapp/github-mark.svg" alt="GitHub" boxSize="40px" />
            </a>
            <a href="https://www.linkedin.com/in/joseguerra09/">
              <Image src="assets/subscriptionapp/linkedin-mark.svg" alt="LinkedIn" boxSize="40px" />
            </a>
            <a href="https://joseguerra.site/">
              <Image src="assets/subscriptionapp/websitelogo.svg" alt="Website" boxSize="40px" />
            </a>
          </HStack>

          <Text mt={2} paddingY='5px'>
            jose.guerra0908@icloud.com
          </Text>

          <Text as="u" mt={2} paddingY='5px'>
            <a href="https://www.termsfeed.com/live/174d4d1f-8a45-4c93-bc93-5b4e169268b6" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </Text>

        </Box>
      </VStack>
    </ChakraProvider>
  );
}

export default SubscriptionApp;
