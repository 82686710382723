import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Heading,
  Center,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react";
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa";
import ProfileArray from "./ProfileArray";

export default function Contact({ color }) {

  const profile = ProfileArray();
  const linkedin = () => {
    window.open(`${profile.linkedin}`, "_blank", "noreferrer,noopener");
  };
  const github = () => {
    window.open(`${profile.github}`, "_blank", "noreferrer,noopener");
  };
  const email = () => {
    window.open(`mailto:${profile.email}`, "_blank", "noreferrer,noopener");
  };


  return (
    <>
      <Container maxW={"3xl"} id="contact">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center" direction="row" p={4}>
            <HStack mx={4}>
              <Text color={`${color}.400`} fontWeight={800}>
                03
              </Text>
              <Text fontWeight={800}>Contacto</Text>
            </HStack>
            <Divider orientation="horizontal" />
          </Stack>

          <Stack spacing={15} as={Container} maxW={"3xl"} textAlign={"center"}>
            <Heading fontSize={"3xl"}>Sigamos en contacto! </Heading>

            <Stack spacing={50}>
              <form name="contact" method="post">
                <input type="hidden" name="form-name" value="contact" />

                <Text as={"b"}>Nombre</Text>
                <Input type="text" name="name" />

                <Text as={"b"}>Email</Text>
                <Input type="email" name="email" />

                <Text as={"b"}>Mensaje</Text>
                <Textarea
                name="message"></Textarea>

                <Button
                  colorScheme={color}
                  bg={`${color}.400`}
                  rounded={"full"}
                  px={6}
                  _hover={{
                    bg: `${color}.500`,
                  }}
                  type="submit"
                >
                  Send
                </Button>
              </form>
            </Stack>

            <Text color={"gray.600"} fontSize={"xl"} px={4}>
              O contáctame directamente a mi correo 👇🏻
            </Text>
            
            <Text
              color={`${color}.500`}
              fontWeight={600}
              fontSize={"lg"}
              px={4}
            >
              {profile.email}
            </Text>
            <Center>
              <HStack pt={4} spacing={4}>
                <FaLinkedin onClick={linkedin} size={28} />
                <FaGithub onClick={github} size={28} />
                <FaEnvelope onClick={email} size={28} />
              </HStack>
            </Center>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

